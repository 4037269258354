// Debounce
window.debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// Boot
require("@rails/ujs").start()
require("turbolinks").start()

// Turbolinks progress bar
Turbolinks.setProgressBarDelay(2000)

// Import stuff
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { TweenLite, TimelineLite, CSSPlugin } from "gsap/TweenMax"

// Vendor
require('cleave.js/dist/cleave.js')

const application = Application.start()
const shared = require.context("./shared", true, /\.js$/)
const smokesmarter = require.context("./smokesmarter", true, /\.js$/)
application.load(definitionsFromContext(shared))
application.load(definitionsFromContext(smokesmarter))